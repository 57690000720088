import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Reason } from '../models/reason';

@Component({
  selector: 'page-reasons',
  templateUrl: 'reasons.html',
  styleUrls: ['./reasons.scss']
})
export class ReasonsPage implements OnInit {

  @Input() public reasons: Array<Reason>;
  @Input() public color: string;

  @Output() selectionMade = new EventEmitter<Reason>();

  constructor(
    private translate: TranslateService
  ) { }

  public ngOnInit() {
    this.reasons.forEach(reason => reason.translate(this.translate.currentLang));
  }

  public selectReason(reason: Reason) {
    reason.selected = !reason.selected;
    this.selectionMade.emit(reason);
    this.reasons.forEach(reason => reason.selected = false);
  }
}
