import { ModalReasonsPage } from './../modal-reasons/modal-reasons';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Reason } from '../models/reason';
import { ModalOptionsService } from './helpers/modal-options.service';

@Injectable()
export class ReasonsDisplayer{
    public constructor(
        private modalService: NgbModal,
        private modalOptions: ModalOptionsService
    ) { }

    public display(reasons: Array<Reason>, color: string): Subject<any> {
        const subscriber = new Subject<any>();

        const modal = this.modalService.open(ModalReasonsPage, this.modalOptions.getOptions('xl', 'custom-modal-option'));
        modal.componentInstance.reasons = reasons.slice();
        modal.componentInstance.color   = color;

        modal.result.catch(data => {
            const reason = data.reason;
            const result = {
                reason: false,
                showComment: false
            };
            if (reason) {
                result["reason"]      = reason;
                result["showComment"] = reason.showComment;
            }

            subscriber.next(result);
        });

        return subscriber;
    }
}
