import { Choice } from './../choice';
import { SmileyColor } from './../smiley-color';
import { Subject } from 'rxjs';
import { QuestionSFD } from './../question-sfd';

export abstract class ScenarioHandler{

    static set currentQuestionIndex(value: number) {
        ScenarioHandler._currentQuestionIndex = value;
        this.emitChangeValues();
    }
    static get currentQuestionIndex() {
        return ScenarioHandler._currentQuestionIndex;
    }

    static set totalSeriesScenario(value: number) {
        ScenarioHandler._totalSeriesScenario = value;
        this.emitChangeValues();
    }

    static get totalSeriesScenario() {
        return ScenarioHandler._totalSeriesScenario;
    }

    private static emitChangeValues() {
        ScenarioHandler.propertiesSubject.next({index: ScenarioHandler.currentQuestionIndex, total: ScenarioHandler.totalSeriesScenario});
    }

    static subscribeChanges() {
        return ScenarioHandler.propertiesSubject;
    }

    private static _currentQuestionIndex = 0;
    private static _totalSeriesScenario = 0;
    private static propertiesSubject: Subject<any> = new Subject<any>();

    public next: ScenarioHandler;

    protected question: QuestionSFD = null;
    protected color: number;
    protected currentQuestion: QuestionSFD;

    abstract defineQuestion(): QuestionSFD;
    abstract getScenario(): string;

    public getReasons(color: string) {
        return this.currentQuestion.reasons.filter(r => color === SmileyColor.getColor(r.sfdButton - 1));
    }

    constructor(next: ScenarioHandler = null) {
        this.next     = next;
        this.color    = 0;
        this.question = null;
    }

    public hasNext() {
        return this.next !== null && this.next !== undefined;
    }

    needDisplayReason() {
        return false;
    }

    needTimeout(): boolean {
        return false;
    }

    hasNextQuestion(choice: Choice = null): boolean {
        return false;
    }

    init() { }

    public getHandler(scenario: string): ScenarioHandler {
        let result = null;
        if (this.getScenario() === scenario) {
            result = this;
        } else if (this.hasNext()) {
            this.next.setQuestion(this.question);
            result = this.next.getHandler(scenario);
        }
        return result;
    }

    public setColor(color: string) {
        this.color = SmileyColor.getIndex(color) + 1;
    }

    public setQuestion(question: QuestionSFD) {
        this.question = question;
        return this;
    }

    public setConfigScenario(scenario: string) {
        return this;
    }
}
