import { Choice } from './../choice';
import { QuestionSFD } from './../question-sfd';
import { QuestionScenario } from './../../models/config-model';
import { ScenarioHandler } from "./scenario-handler";
import { QuestionSerie } from '../question-serie';

export class SerieHandler extends ScenarioHandler{
    private currentQuestionIndex;

    constructor(next: ScenarioHandler) {
        super(next);
        this.currentQuestionIndex = 0;
    }

    needDisplayReason() {
        return true;
    }

    needTimeout() {
        return true;
    }

    hasNextQuestion(choice: Choice = null) {
        let result = false;

        // add one to take in account question sfd as the first of series (without array)
        let lengthSerie = this.getSeries(this.question).length + 1;
        if (this.currentQuestionIndex < lengthSerie) {
            result = true;
        } else {
            ScenarioHandler.totalSeriesScenario = lengthSerie;
            ScenarioHandler.currentQuestionIndex = this.currentQuestionIndex;
        }
        // console.log('serie handler hasNextQuestion', result);
        return result;
    }

    getScenario() {
        return QuestionScenario.SERIE;
    }

    defineQuestion() {
        let result: QuestionSFD = null;
        const series = this.getSeries(this.question);
        const lengthSeries = series.length + 1;
        if (series && series.length) {
            if (this.currentQuestionIndex == 0) {
                result = QuestionSFD.copy(this.question);
            } else if (this.currentQuestionIndex < lengthSeries) {
                result = QuestionSFD.createFromQuestionSerie(series[this.currentQuestionIndex - 1]);
            }
            ScenarioHandler.currentQuestionIndex = this.currentQuestionIndex;
            this.currentQuestionIndex++;
        } else if (!series || !series.length) {
            result = QuestionSFD.copy(this.question);
        }

        ScenarioHandler.totalSeriesScenario = series.length;
        this.currentQuestion = result;
        return result;
    }

    private getSeries(question: QuestionSFD): Array<QuestionSerie> {
        let result = [];
        if (question.series && question.series.length) {
            result = question.series.sort((a, b) => a.orderNumber - b.orderNumber);
        }
        return result;
    }
}
