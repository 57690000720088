import { SmileyColor } from './../smiley-color';
import { QuestionSFD } from '../question-sfd';
import { QuestionScenario } from '../config-model';
import { ScenarioHandler } from "./scenario-handler";

export class SingleHandler extends ScenarioHandler {

  needDisplayReason() {
    return true;
  }

  getScenario() {
    return QuestionScenario.SINGLE;
  }

  defineQuestion() {
    return QuestionSFD.copy(this.question);
  }

  public getReasons(color: string) {
    return this.question.reasons
      .filter(r => color === SmileyColor.getColor(r.sfdButton - 1))
      .sort((a, b) => a.reasonOrder - b.reasonOrder);
  }
}
