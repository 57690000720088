import { Reason } from './reason';
export class QuestionSerie{
    public dateUpdate: Date;
    public id: number;
    public idQuestion: number;
    public levelNumber: number;
    public orderNumber: number;
    public previousColor: number;
    public previousNo: number;
    public previousRandom: string;
    public questionDate: Date;
    public language1: string;
    public text1: string;
    public language2: string;
    public text2: string;
    public language3: string;
    public text3: string;
    public language4: string;
    public text4: string;
    public language5: string;
    public text5: string;
    public questionNo: number;
    public isQuestion: boolean;
    public isReason: boolean;
    public reasons: Array<Reason>;
    public image: string;
    public showComment: boolean;
    public commentTitle: string;
    public commentSubtitle: string;

    public constructor(
        dateUpdate     = new Date(),
        id             = -1,
        idQuestion     = -1,
        levelNumber    = -1,
        orderNumber    = 0,
        previousColor  = 0,
        previousNo     = 0,
        previousRandom = "",
        questionDate   = new Date(),
        language1      = "",
        text1          = "",
        language2      = "",
        text2          = "",
        language3      = "",
        text3          = "",
        questionNo     = 0,
        isQuestion     = false,
        isReason       = false,
        image          = "",
        showComment    = false,
        commentTitle   = '',
        commentSubtitle = ''
    ) {
        this.dateUpdate     = dateUpdate;
        this.id             = id;
        this.idQuestion     = idQuestion;
        this.levelNumber    = levelNumber;
        this.orderNumber    = orderNumber;
        this.previousColor  = previousColor;
        this.previousNo     = previousNo;
        this.previousRandom = previousRandom
        this.questionDate   = questionDate;
        this.language1      = language1;
        this.text1          = text1;
        this.language2      = language2;
        this.text2          = text2;
        this.language3      = language3;
        this.text3          = text3;
        this.questionNo     = questionNo;
        this.isQuestion     = isQuestion;
        this.isReason       = isReason;
        this.image          = image;
        this.reasons        = [];
        this.showComment    = showComment;
        this.language4 = "";
        this.language5 = "";
        this.text4 = "";
        this.text5 = "";
        this.commentTitle = commentTitle;
        this.commentSubtitle = commentSubtitle;
    }

    public static createFromJson(json: any) {
      const result = new QuestionSerie(
          json.DateUpdate,
          json.ID,
          json.ID_Question,
          json.LevelNumber,
          json.OrderNumber,
          json.Previous_Color,
          json.Previous_No,
          json.Previous_Random,
          json.QuestionDate,
          json.QuestionReason1Language,
          json.QuestionReason1Text,
          json.QuestionReason2Language,
          json.QuestionReason2Text,
          json.QuestionReason3Language,
          json.QuestionReason3Text,
          json.Question_No,
          false,
          false,
          json.QuestionReasonImage,
          json.ShowComment !== 0,
          json.commentPrompt,
          json.commentInstructions
      );

      if (json.LevelQuestionOrReason == "R") {
          result.isReason = true;
      } else if (json.LevelQuestionOrReason == "Q") {
          result.isQuestion = true;
      }

      return result;
    }

    public static copy(serie: QuestionSerie) {
        const result = new QuestionSerie(
            serie.dateUpdate,
            serie.id,
            serie.idQuestion,
            serie.levelNumber,
            serie.orderNumber,
            serie.previousColor,
            serie.previousNo,
            serie.previousRandom,
            serie.questionDate,
            serie.language1,
            serie.text1,
            serie.language2,
            serie.text2,
            serie.language3,
            serie.text3,
            serie.questionNo,
            serie.isQuestion,
            serie.isReason,
            serie.image,
            serie.showComment,
            serie.commentTitle,
            serie.commentSubtitle
        );
        result.language4 = serie.language4;
        result.text4 = serie.text4;
        result.language5 = serie.language5;
        result.text5 = serie.text5;
        result.reasons = Reason.copyList(serie.reasons);
        return result;
    }

    public static copyList(list: Array<QuestionSerie>) {
       return list.map(serie => QuestionSerie.copy(serie));
    }

    public static createFromJsonList(jsonList: Array<any>): Array<QuestionSerie> {
        const result = this.mapReasonsWithQuestion(jsonList.map(json => this.createFromJson(json)));
        return result;
    }

    public static mapReasonsWithQuestion(series: Array<QuestionSerie>) {
        const result  = series.filter(serie => serie.isQuestion);
        const reasons = series.filter(serie => serie.isReason); // convert to reason

        result.forEach(serie => {
            serie.reasons = Reason.createListFromSerie(reasons.filter(r => r.previousNo === serie.id));
        });
        return result;
    }

}
