import { Injectable } from "@angular/core";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ModalOptionsService {
    constructor() {}

    getOptions(size: 'lg' | 'xl' | 'sm' = null, customClasses: string = ''): NgbModalOptions {
        const result: NgbModalOptions = {
            keyboard: false,
            backdrop: 'static',
            centered: true,
        }

        if (size) {
            result.size = size;
        }
        if (customClasses) {
            result.windowClass = customClasses;
        }
        return result;
    }
}
