import { Component } from '@angular/core'
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Virtual smileybox';

  public constructor(
    private config: NgbModalConfig
  ) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
  }
}
