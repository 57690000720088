import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCampaign } from '../modal-campaign/modal-campaign';

@Injectable()
export class CampaignDisplayer{
    public constructor(
        private modalService: NgbModal
    ) { }

    public display(campaign_id: number, recipient_id: number): Subject<any> {
        const subscriber = new Subject<any>();
        const modal = this.modalService.open(ModalCampaign, {
            centered: true
        });
        modal.componentInstance.campaign_id = campaign_id;
        modal.componentInstance.recipient_id = recipient_id;

        modal.result.catch(data => {
            subscriber.next(data);
        });
        return subscriber;
    }
}