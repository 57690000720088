export class State{
    public static readonly CHOOSE_QUESTION = "CHOOSE_QUESTION";
    public static readonly WAIT_VOTE       = "WAIT_VOTE";
    public static readonly VOTE            = "VOTE";
    public static readonly DISPLAY_REASONS = "DISPLAY_REASONS";
    public static readonly DISPLAY_COMMENT = "DISPLAY_COMMENT";
    public static readonly DISPLAY_CONTACT = "DISPLAY_CONTACT";
    public static readonly CHECK_NEXT      = "CHECK_NEXT";
    public static readonly SEND            = "SEND";
    public static readonly INIT            = "INIT";
    public static readonly BLOCKED         = "BLOCKED";
}