import { SmileyColor } from './../smiley-color';
import { Choice } from './../choice';
import { QuestionSFD } from '../question-sfd';
import { QuestionScenario } from '../config-model';
import { ScenarioHandler } from "./scenario-handler";

export class TreeHandler extends ScenarioHandler{

    private currentLevel: number;
    private nextQuestion: QuestionSFD;

    constructor(next: ScenarioHandler = null) {
        super(next);
        this.currentLevel = 1;
        this.nextQuestion = null;
    }

    needTimeout() {
        return true;
    }

    needDisplayReason() {
        return true;
    }

    hasNextQuestion(choice: Choice = null) {
        return this.findNext(choice) !== null;
    }

    getScenario() {
        return QuestionScenario.TREE;
    }

    defineQuestion() {
        let question = this.nextQuestion;
        if (!question) {
            // for the first case where nextQuestion is not computed, the current level must be equals to 1
            question = QuestionSFD.copy(this.question);
        } else {
            this.currentLevel ++;
        }
        this.currentQuestion = question;
        return question;
    }

    getReasons(color: string) {
        const result = this.currentQuestion.reasons.filter(reason => reason.sfdButton === (SmileyColor.getIndex(color) + 1));
        return result;
    }

    // find next question to display according to last choice made by the users
    private findNext(choice: Choice = null) {
        let result: QuestionSFD = null;
        if (!choice) {
            result = QuestionSFD.copy(this.question);
        } else {
            let serie = null;
            if (choice.hasReason() && this.currentLevel > 1) {
                const idReason = choice.reason.id;
                serie = this.question.series.find(serie => serie.previousNo === idReason);
            } else if (this.currentLevel > 1){
                serie = this.question.series.find(serie => serie.previousColor === (SmileyColor.getIndex(choice.color) + 1) && serie.previousNo === this.currentQuestion.id);
            } else {
              serie = this.question.series.find(serie => serie.previousColor === (SmileyColor.getIndex(choice.color) + 1) && serie.previousNo === null);
            }
            if (serie) {
                result = QuestionSFD.createFromQuestionSerie(serie);
            }
        }

        this.nextQuestion = result;
        return result;
    }
}
