import { ModalContactComponent } from './../modal-contact/modal-contact.component';
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptionsService } from './helpers/modal-options.service';

@Injectable()
export class ContactDisplayer{
    public constructor(
        private modalService: NgbModal,
        private modalOption: ModalOptionsService
    ) { }

    public display(): Subject<any> {
        const subscriber = new Subject<any>();
        const modal = this.modalService.open(ModalContactComponent, this.modalOption.getOptions('lg'));
        modal.result.catch(data => {
            subscriber.next(data);
        });
        return subscriber;
    }
}
