import { ImageHelperService } from './providers/helpers/image-helper.service';
import { VotesStatsService } from './thank-you-page/votes-stats.service';
import { LoaderService } from './providers/loader.service';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { ContactDisplayer } from './providers/contact-displayer';
import { FBNLanguageService } from './providers/fbn-language.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommentDisplayer } from './providers/comment-displayer';
import { ThanksDisplayer } from './providers/thanks-displayer';
import { VotesManager } from './providers/votes-services/votes-manager';
import { FBNHttpProvider } from './providers/fbn-http-provider';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { VotePageComponent } from './vote-page/vote-page.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Engine } from './providers/votes-services/engine';
import { ReasonsDisplayer } from './providers/reasons-displayer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommentPage } from './modal-comment/modal-comment';
import { ModalThanks } from './modal-thanks/modal-thanks';
import { ModalReasonsPage } from './modal-reasons/modal-reasons';
import { FormsModule } from '@angular/forms';
import { ReasonsPage } from './reason/reasons';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ModalCampaign } from './modal-campaign/modal-campaign';
import { CampaignDisplayer } from './providers/campaign-displayer';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from './providers/db.config';
import { IdbService } from './providers/idb.service';
import { ModalOptionsService } from './providers/helpers/modal-options.service';
import { HeaderComponent } from './header/header.component';
import { SettingsService } from './providers/settings.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const appRoutes: Routes = [{
  path: '',
  children: [{
    path: ':vsfdName',
    component: VotePageComponent,
  }, {
    path: ':vsfdName/:campaign_id/:recipient_id',
    component: VotePageComponent,
  }, {
    path: ':strategy/:vsfd',
    component: VotePageComponent
  }
  ]
}, {
  path: 'thanks',
  component: ThankYouPageComponent,
}
];
@NgModule({

  entryComponents: [
    ModalCommentPage,
    ModalThanks,
    ModalReasonsPage,
    ModalContactComponent,
    ModalCampaign
  ],
  declarations: [
    AppComponent,
    VotePageComponent,
    ThankYouPageComponent,
    ModalCommentPage,
    ModalThanks,
    ModalReasonsPage,
    ModalContactComponent,
    ReasonsPage,
    ModalCampaign,
    HeaderComponent
  ],
  imports: [
    ChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- log all routing event here.
    ),
    BrowserModule,
    HttpClientModule,
    HttpModule,
    NgbModule,
    FormsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    FBNHttpProvider,
    Engine,
    VotesManager,
    ReasonsDisplayer,
    ThanksDisplayer,
    CommentDisplayer,
    ContactDisplayer,
    FBNLanguageService,
    CampaignDisplayer,
    IdbService,
    FBNLanguageService,
    LoaderService,
    VotesStatsService,
    ImageHelperService,
    ModalOptionsService,
    SettingsService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
