import { ModalCommentPage } from './../modal-comment/modal-comment';
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { QuestionSFD } from '../models/question-sfd';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptionsService } from './helpers/modal-options.service';

@Injectable()
export class CommentDisplayer{
    public constructor(
        private modalService: NgbModal,
        private modalOptions: ModalOptionsService
    ) { }

    public display(question: QuestionSFD, textAbove: string = '', textUnder: string = ''): Subject<any> {
        const subscriber = new Subject<any>();
        const modal = this.modalService.open(ModalCommentPage, this.modalOptions.getOptions('lg'));
        modal.componentInstance.question = question;
        modal.componentInstance.textAbove = textAbove;
        modal.componentInstance.textUnder = textUnder;
        modal.result.catch(data => {
            subscriber.next(data);
        });
        return subscriber;
    }
}
