import { TreeHandler } from './tree-handler';
import { SerieHandler } from './serie-handler';
import { RandomHandler } from './random-handler';
import { SingleHandler } from './single-handler';
import { QuestionSFD } from '../question-sfd';

export class ScenarioHandlerFactory{
    public static create(questionSFD: QuestionSFD = null) {
        return new SingleHandler(new RandomHandler(new SerieHandler(new TreeHandler()))).setQuestion(QuestionSFD.copy(questionSFD));
    }
}