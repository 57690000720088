import { QuestionScenario } from './../config-model';
import { Choice } from './../choice';
import { QuestionSFD } from '../question-sfd';
import { ScenarioHandler } from "./scenario-handler";

export class RandomHandler extends ScenarioHandler{

    private questions: Array<QuestionSFD>;
    private currentQuestionIndex;

    needDisplayReason() {
        return true;
    }

    needTimeout() {
        return true;
    }

    hasNextQuestion(choice: Choice = null): boolean {
        let result = false;

        // add one to take in account question sfd as the first of series (without array)
        if (this.currentQuestionIndex < this.questions.length) {
            result = true;
        } else {
            ScenarioHandler.totalSeriesScenario = this.questions.length
            ScenarioHandler.currentQuestionIndex = this.currentQuestionIndex;
        }
        return result;
    }

    init() {
        let questions = this.question.series.map(serie => QuestionSFD.createFromQuestionSerie(serie));
        questions.unshift(QuestionSFD.copy(this.question));
        this.questions = this.shuffle(questions);
        this.currentQuestionIndex = 0;
    }

    public getScenario() {
        return QuestionScenario.RANDOM;
    }

    public defineQuestion() {
        let result: QuestionSFD = null;
        if (this.questions && this.questions.length) {
            if (this.currentQuestionIndex < this.questions.length) {
                result = this.questions[this.currentQuestionIndex];
            }
            ScenarioHandler.currentQuestionIndex = this.currentQuestionIndex;
            this.currentQuestionIndex++;
        }
        this.currentQuestion = result;
        return result;
    }

    private shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex --;

          // And swap it with the current element.
          temporaryValue      = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex]  = temporaryValue;
        }

        return array;
      }
}
