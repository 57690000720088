import { VotesStatsService } from './../../thank-you-page/votes-stats.service';
import { ContactDisplayer } from './../contact-displayer';
import { FBNLanguageService } from './../fbn-language.service';
import { Config } from './../../models/config-model';
import { Subscription, Subject, timer, config } from 'rxjs';
import { CommentDisplayer } from './../comment-displayer';
import { ThanksDisplayer } from './../thanks-displayer';
import { Reason } from './../../models/reason';
import { Choice } from './../../models/choice';
import { State } from '../../models/state';
import { SmileyColor } from '../../models/smiley-color';
import { Vote } from './../../models/votes';
import { ScenarioHandlerFactory } from './../../models/question-scenario/scenario-handler-factory';
import { Injectable } from '@angular/core';
import { QuestionSFD } from "../../models/question-sfd";
import { VotesManager } from './votes-manager';
import * as moment from 'moment';
import { ScenarioHandler } from '../../models/question-scenario/scenario-handler';
import { ReasonsDisplayer } from '../reasons-displayer';
import { TranslateService } from '@ngx-translate/core';
import { IdbService } from '../idb.service';
import { SettingsService } from '../settings.service';

@Injectable()
export class Engine {

  public mainQuestion: QuestionSFD;
  public lastSend: Date;
  public reasons: Array<Reason>;

  private blockingTime: number;
  private QuestionScenario: string;
  private useReason: boolean;
  private sfdName: string;
  private choices: Array<Choice>;
  private subscriber: Subject<any>;
  private scenarioHandler: ScenarioHandler;
  private commentPolicy: string;
  private contactPolicy: string;
  private choice: Choice;
  private timeoutSubscriber: Subscription;
  private contactDetail: string;
  private justInit: boolean;
  private recipientId: number;
  private textThankYou: string;
  private isBlocked: boolean;
  private browserBlocking: number;
  private vsbDateUpdate: string;
  private textThankYouEnabled: boolean;

  private _question: QuestionSFD;
  private _state: string;

  private get state(): string {
    return this._state;
  }

  private set state(value: string) {
    this._state = value;
    this.manageState();
  }

  private get lastChoice(): Choice {
    let length = 0;
    let result = null;
    if (this.choices && (length = this.choices.length)) {
      result = this.choices[length - 1];
    }
    return result;
  }

  public get question(): QuestionSFD {
    return this._question;
  }

  public set question(value: QuestionSFD) {
    this._question = value;
    this.emitEventQuestion();
  }

  public showStatsThankYou: boolean;

  public constructor(
    private votesManager: VotesManager,
    private reasonsDisplayer: ReasonsDisplayer,
    private thanksDisplayer: ThanksDisplayer,
    private commentDisplayer: CommentDisplayer,
    private contactDisplayer: ContactDisplayer,
    private translate: TranslateService,
    public fbnLanguage: FBNLanguageService,
    private storage: IdbService,
    public votesStats: VotesStatsService,
    public settings: SettingsService
  ) {
    this.lastSend = null;
    this.subscriber = new Subject();
    this.sfdName = "";
    this.choices = new Array<Choice>();
    this.blockingTime = 0;
    this.scenarioHandler = ScenarioHandlerFactory.create();
    this.timeoutSubscriber = null;
    this.justInit = false;
    this.isBlocked = false;
    this.browserBlocking = 0;
    this.vsbDateUpdate = "";
    this.showStatsThankYou = false;
    this.textThankYouEnabled = false;
  }

  public init(question: QuestionSFD, config: Config) {
    this.choices = new Array<Choice>();
    this.mainQuestion = question;
    this.QuestionScenario = config.scenario;
    this.sfdName = config.sfdName;
    this.useReason = config.reasonEnabled;
    this.commentPolicy = config.commentPolicy;
    this.contactPolicy = config.contactPolicy;
    this.blockingTime = config.blockingTime;
    this.textThankYou = config.textThankYou;
    this.vsbDateUpdate = config.dateUpdate;
    this.showStatsThankYou = config.showStatEnabled;
    this.textThankYouEnabled = config.thankYouEnabled;

    QuestionSFD.languagesCode = this.fbnLanguage.codes;
    Reason.languagesCode = this.fbnLanguage.codes;
    ScenarioHandler.currentQuestionIndex = 0;
    ScenarioHandler.totalSeriesScenario = 0;

    const language = question.getCurrentLanguage();
    const code = this.fbnLanguage.getCode(language);

    this.translate.use(code);

    // reset all handler for new process
    this.scenarioHandler = ScenarioHandlerFactory.create(this.mainQuestion);

    this.isBlocked = config.ipBlocked;
    this.browserBlocking = config.browserBlocking;

    this.state = State.INIT;
  }

  public getEventSubscriber() {
    setTimeout(() => this.emitEventQuestion());
    return this.subscriber;
  }

  public emitEventQuestion() {
    this.subscriber.next({ name: 'question', data: { question: this.question, justInit: this.justInit } });
  }

  public emitEventReset(propagate: boolean = true) {
    this.subscriber.next({ name: 'reset', data: { propagate: propagate } });
  }

  public vote(color: string, recipientId = null) {
    this.choice = new Choice(color, this.question);
    this.state = State.VOTE;
    this.recipientId = recipientId;
  }

  private getHandler() {
    return this.scenarioHandler.getHandler(this.QuestionScenario);
  }

  private resetTimeout() {
    if (this.timeoutSubscriber) {
      this.timeoutSubscriber.unsubscribe();
      this.timeoutSubscriber = null;
    }
  }

  private manageState() {
    let handler = this.getHandler();
    switch (this.state) {
      case State.INIT:
        this.justInit = true;
        handler.init();
        this.state = State.CHOOSE_QUESTION;
        break;
      case State.CHOOSE_QUESTION:
        this.choice = null;
        this.question = handler.defineQuestion();
        this.checkBrowserBlocking().then(browserBlocked => {
          this.isBlocked = this.isBlocked || browserBlocked;
          if (this.isBlocked) {
            this.state = State.BLOCKED;
          } else {
            this.state = State.WAIT_VOTE
          }
        });
        break;
      case State.WAIT_VOTE:
        if (handler.needTimeout() && this.choices.length) {
          this.timeoutSubscriber = timer(this.settings.config.inactivityTimeOut * 1000).subscribe(() => {
            this.state = State.SEND;
          });
        }
        break;
      case State.VOTE:
        this.justInit = false;
        handler.setColor(this.choice.color);
        this.resetTimeout();
        this.state = State.DISPLAY_REASONS;
        break;
      case State.DISPLAY_REASONS:
        let reasonDisplayed = false;
        if (handler.needDisplayReason()) {
          const reasons = handler.getReasons(this.choice.color);
          if (this.useReason && reasons.length) {
            reasonDisplayed = true;
            this.displayReasons(reasons);
          }
        }

        if (!reasonDisplayed) {
          this.state = State.DISPLAY_COMMENT;
        }
        break;
      case State.DISPLAY_COMMENT:
        if (this.needDisplayComment()) {
          this.displayComment();
        } else {
          this.state = State.CHECK_NEXT;
        }
        break;
      case State.BLOCKED:
        this.displayModalThanks(false);
        break;
      case State.CHECK_NEXT:
        if (this.choice) {
          this.choices.push(Choice.copy(this.choice));
        }
        const hasNext = handler.hasNextQuestion(this.lastChoice);
        if (hasNext) {
          this.state = State.CHOOSE_QUESTION;
        } else {
          this.state = State.DISPLAY_CONTACT;
        }
        break;
      case State.DISPLAY_CONTACT:
        if (this.needContact(this.choice.color)) {
          this.displayContact();
        } else {
          this.state = State.SEND;
        }
        break;
      case State.SEND:
        this.send();
        break;
    }
  }

  public send() {
    const votes = Vote.createFromChoices(this.choices, this.sfdName);
    this.votesStats.addVotes(votes);
    if (votes.length) {
      this.votesManager.sendVotes(votes, this.contactDetail, this.recipientId).subscribe(data => {
        this.handleStorageBlocking().then(blocked => {
          this.isBlocked = blocked || data.isBlocked;
        })
        this.displayModalThanks();
      });
      this.lastSend = moment().toDate();
    }
  }

  private reset(propagate: boolean = true) {
    this.choices = new Array<Choice>();
    ScenarioHandler.currentQuestionIndex = 0;
    ScenarioHandler.totalSeriesScenario = 0;
    // reset all handler for new process
    this.scenarioHandler = ScenarioHandlerFactory.create(this.mainQuestion);
    this.recipientId = null;
    this.state = State.INIT;
    this.emitEventReset(propagate);
  }

  private needDisplayComment() {
    let result = false;
    let questionCondition = false;
    if (this.question.fromSerie) {
      questionCondition = this.question.showComment || this.needComment(this.choice.color);
    } else {
      questionCondition = this.needComment(this.choice.color);
    }
    const reasonCondition = (this.choice.reason != null && this.choice.reason !== undefined && this.choice.reason.showComment);
    result = questionCondition || reasonCondition;
    return result;
  }


  public displayComment() {
    const questionTitle = this.question.commentTitle;
    const questionSubtitle = this.question.commentSubtitle
    const textAbove = questionTitle ? questionTitle : this.settings.config.commentPrompt;
    const textUnder = questionSubtitle ? questionSubtitle : this.settings.config.commentInstructions;

    this.commentDisplayer.display(this.question, textAbove, textUnder).subscribe(data => {
      if (data) {
        this.choice.comment = data.comment;
        this.state = State.CHECK_NEXT;
      }
    });
  }

  public displayContact() {
    this.contactDisplayer.display().subscribe(data => {
      this.contactDetail = data.contact;
      this.state = State.SEND;
    });
  }

  public displayReasons(reasons: Array<Reason>) {
    this.reasonsDisplayer.display(reasons, this.choice.color).subscribe(data => {
      if (this.choice) {
        this.choice.reason = data.reason;
        const {reason} = this.choice;
        if (reason) {
          const {commentTitle, commentSubtitle} = reason

          if (commentTitle) {
            this.question.commentTitle = commentTitle;
          }
          if (commentSubtitle) {
            this.question.commentSubtitle = commentSubtitle;
          }
        }
        if (data.showComment) {
          this.state = State.DISPLAY_COMMENT;
        } else {
          this.state = State.CHECK_NEXT;
        }
      } else {
        this.state = State.CHECK_NEXT;
      }
    });
  }

  public displayModalThanks(dismiss: boolean = true) {
    const text = this.textThankYouEnabled ? this.textThankYou : '';
    this.thanksDisplayer.display(text, this.blockingTime, this.showStatsThankYou, dismiss).subscribe(() => {
      this.reset(false);
    });
  }

  private checkBrowserBlocking(): Promise<boolean> {
    return this.storage.findValidity(this.sfdName).then(validity => {
      let isBlocked = false;
      let needDelete = false;

      if (validity) {
        if (this.browserBlocking != 0) {
          if (moment(validity.dateUpdate).isBefore(moment(this.vsbDateUpdate)) || !validity.isLocked()) {
            needDelete = true;
          } else {
            isBlocked = validity.isLocked();
          }
        } else {
          // remove in database
          needDelete = true;
        }
      }
      if (needDelete) {
        this.storage.delete(this.sfdName).then(result => {
        })
      }
      return isBlocked;
    });
  }

  private handleStorageBlocking(): Promise<boolean> {
    let result = null;
    let unlockDate = "";
    if (this.browserBlocking === -1) {
      unlockDate = moment().add(100, "years").format();
    } else if (this.browserBlocking > 0) {
      unlockDate = moment().add(this.browserBlocking, "hours").format();
    }

    if (unlockDate.length) {
      result = this.storage.add(this.sfdName, unlockDate, this.vsbDateUpdate).then(() => {
        return true;
      })
    } else {
      result = Promise.resolve(false);
    }
    return result;
  }

  private needComment(color: string) {
    return this.checkPolicy(this.commentPolicy, color);
  }

  private needContact(color: string) {
    return this.checkPolicy(this.contactPolicy, color);
  }

  private checkPolicy(policy: string, color: string) {
    const index = SmileyColor.getIndex(color);
    return +policy.split('')[index] === 1;
  }
}
