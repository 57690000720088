import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionSFD } from '../models/question-sfd';
import { SettingsService } from '../providers/settings.service';

@Component({
  selector: 'modal-comment',
  templateUrl: 'modal-comment.html',
  styleUrls: ['./modal-comment.scss']
})
export class ModalCommentPage implements OnInit {
  @Input() question: QuestionSFD;

  public comment: string;
  public showSubmitButton: boolean;
  public questionText: string;
  public textAbove: string;
  public textUnder: string;

  private timer: Subscription;

  @ViewChild('textcomment', { static: false }) textarea;

  constructor(
    public activeModal: NgbActiveModal,
    private settings: SettingsService
  ) {
    this.comment = '';
    this.showSubmitButton = true;
    this.timer = null;
    this.textAbove = '';
    this.textUnder = '';

    this.initTimer();
  }

  initTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.timer = timer(this.settings.config.inactivityTimeOut * 1000).subscribe(() => {
      this.dismiss();
    });
  }

  ngOnInit() {
    const text = this.question.getCurrentText();
    this.questionText = text;
    if (this.settings.config.minCommentLength > 0) {
      this.showSubmitButton = false;
    }
  }

  dismiss() {
    const comment = this.comment.length >= this.settings.config.minCommentLength ? this.comment : '';
    let data = {
      comment: comment
    };
    this.activeModal.dismiss(data);
  }

  keyPressed() {
    if (this.comment.length >= this.settings.config.minCommentLength) {
      this.showSubmitButton = true;
    } else {
      this.showSubmitButton = false;
    }
    this.initTimer();
  }

  buttonClicked() {
    this.dismiss();
  }
}
