import { QuestionSerie } from './question-serie';
import * as moment from 'moment';
import { Parent } from './parent';

export class Reason extends Parent {

  public static GREEN = 1;
  public static YELLOW = 2;
  public static RED = 3;

  public idQuestion: number;
  public dateUpdate: Date;
  public sfdButton: number;
  public reason1Language: string;
  public reason1Text: string;
  public reason2Language: string;
  public reason2Text: string;
  public reason3Language: string;
  public reason3Text: string;
  public reason4Language: string;
  public reason4Text: string;
  public reason5Language: string;
  public reason5Text: string;
  public reasonOrder: number;

  public reasonText: string;

  public selected: boolean;
  public showComment: boolean;

  public icon: string;
  public static languagesCode: any;

  public commentTitle: string;
  public commentSubtitle: string;

  public constructor(
    id = -1,
    idQuestion = -1,
    dateUpdate = new Date(),
    sfdButton = 0,
    reasonOrder = 0,
    reason1Language = "",
    reason2Language = "",
    reason3Language = "",
    reason4Language = "",
    reason5Language = "",
    reason1Text = "",
    reason2Text = "",
    reason3Text = "",
    reason4Text = "",
    reason5Text = "",
    selected = false,
    showComment = false,
    icon = "",
    commentTitle = '',
    commentSubtitle = ''
  ) {
    super();
    this.id = id;
    this.idQuestion = idQuestion;
    this.dateUpdate = dateUpdate;
    this.sfdButton = sfdButton;
    this.reasonOrder = reasonOrder;
    this.reason1Language = reason1Language;
    this.reason2Language = reason2Language;
    this.reason3Language = reason3Language;
    this.reason4Language = reason4Language;
    this.reason5Language = reason5Language;
    this.reason1Text = reason1Text;
    this.reason2Text = reason2Text;
    this.reason3Text = reason3Text;
    this.reason4Text = reason4Text;
    this.reason5Text = reason5Text;
    this.selected = selected;
    this.showComment = showComment;
    this.icon = icon;
    this.commentTitle = commentTitle;
    this.commentSubtitle = commentSubtitle;
  }

  public static createFromJsonList(jsonList: Array<any>): Array<Reason> {
    return jsonList.map(json => this.createFromJson(json));
  }

  public static createFromJson(json: any): Reason {
    return new Reason(
      json.ID,
      json.ID_Question,
      moment(json.DateUpdate).toDate(),
      json.SFDButton,
      json.ReasonOrder,
      json.Reason1Language,
      json.Reason2Language,
      json.Reason3Language,
      json.Reason4Language,
      json.Reason5Language,
      json.Reason1Text,
      json.Reason2Text,
      json.Reason3Text,
      json.Reason4Text,
      json.Reason5Text,
      false,
      json.ShowComment !== 0,
      (json.ReasonIcon ? ("data:image/png;base64," + json.ReasonIcon) : ""),
      json.commentPrompt,
      json.commentInstructions
    );
  }

  public static copy(reason: any) {
    let result = new Reason();
    result.id = reason.id;
    result.idQuestion = reason.idQuestion;
    result.dateUpdate = reason.dateUpdate;
    result.sfdButton = reason.sfdButton;
    result.reason1Language = reason.reason1Language;
    result.reason1Text = reason.reason1Text;
    result.reason2Language = reason.reason2Language;
    result.reason2Text = reason.reason2Text;
    result.reason3Language = reason.reason3Language;
    result.reason3Text = reason.reason3Text;
    result.reason4Language = reason.reason4Language;
    result.reason4Text = reason.reason4Text;
    result.reason5Language = reason.reason5Language;
    result.reason5Text = reason.reason5Text;
    result.reasonOrder = reason.reasonOrder;
    result.showComment = reason.showComment;
    result.icon = reason.icon;
    result.commentTitle = reason.commentTitle;
    result.commentSubtitle = reason.commentSubtitle;
    return result;
  }

  public static copyList(list: Array<any>) {
    return list && list.length ? list.map(reason => this.copy(reason)) : [];
  }

  public static createFromSerie(serie: QuestionSerie) {
    const reasonIcon = (serie.image ? ("data:image/png;base64," + serie.image) : "")
    return new Reason(
      serie.id,
      serie.idQuestion,
      serie.dateUpdate,
      serie.previousColor,
      serie.orderNumber,
      serie.language1,
      serie.language2,
      serie.language3,
      "",
      "",
      serie.text1,
      serie.text2,
      serie.text3,
      "",
      "",
      false,
      serie.showComment,
      reasonIcon,
      serie.commentTitle,
      serie.commentSubtitle
    );
  }

  public static createListFromSerie(list: Array<QuestionSerie>): Array<Reason> {
    return list.map(serie => this.createFromSerie(serie));
  }

  public translate(languageCode: string) {
    let result = '';

    let language = Reason.languagesCode[languageCode];
    if (language) {
      for (let i = 1; i < 6; i++) {
        const base = 'reason' + i;
        const languageKey = base + 'Language';
        if (this[languageKey] === language) {
          result = this[base + 'Text'];
        }
      }
    }

    if (!result.length) {
      // find the first language having text
      for (let i = 1; i < 6; i++) {
        const base = 'reason' + i;
        const languageKey = base + 'Language';
        const textKey = base + 'Text';
        if (this[languageKey] && this[languageKey].length && this[textKey] && this[textKey].length) {
          result = this[textKey];
          break;
        }
      }
    }

    this.reasonText = result;
  }
}
