import { Vote } from './../models/votes';
import { LoaderService } from './../providers/loader.service';
import { FBNHttpProvider } from './../providers/fbn-http-provider';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { StatsSettings, StatPeriod } from '../models/stats-model';

@Injectable()
export class VotesStatsService {

    private greenResult: number;
    private yellowResult: number;
    private redResult: number;
    private settings: StatsSettings;
    private startDate: string;
    private endDate: string;

    private localVotes: any;

    private readonly LOCAL_VOTE_KEY = 'local_vote_day';

    constructor(
        private http: FBNHttpProvider,
        private loader: LoaderService
    ) {
        this.initLocalVotes();
        this.initServerVotes();
        const today = moment().format();
        this.startDate = today;
        this.endDate = today;
        this.loader.onConfigInit.subscribe(config => {
            if (config && config.showStatEnabled) {
                this.settings = config.statsSettings;
                this.computeDates();
                this.loadFromServer();
            }
        });
    }

    private initServerVotes() {
        this.greenResult = 0;
        this.yellowResult = 0;
        this.redResult = 0;
    }

    private resetLocalVotes() {
        this.localVotes = {
            green: 0,
            yellow: 0,
            red: 0
        };
    }

    private initLocalVotes() {
        this.resetLocalVotes();
        let localVotes: any = localStorage.getItem(this.LOCAL_VOTE_KEY);
        if (localVotes) {
            localVotes = JSON.parse(localVotes);
            this.localVotes.green = localVotes.green;
            this.localVotes.yellow = localVotes.yellow;
            this.localVotes.red = localVotes.red;
        }
    }

    public addVote(vote: Vote) {
        this.localVotes[vote.color] ++;
        this.saveLocalVotes();
    }

    public addVotes(votes: Array<Vote>) {
        votes.forEach(v => this.localVotes[v.color] ++);
        this.saveLocalVotes();
    }

    public clean() {
        this.resetLocalVotes();
        this.saveLocalVotes();
    }

    public getDatas() {
        return [
            ['green', this.greenResult + this.localVotes.green],
            ['yellow', this.yellowResult + this.localVotes.yellow],
            ['red', this.redResult + this.localVotes.red]
        ];
    }

    public loadFromServer() {
        // subscribe to event from server, load datas
        this.http.post('stats', {
            SFDName: this.loader.getName(),
            dateStart: this.startDate,
            dateEnd: this.endDate
        }).subscribe(response => {
            const result = response.json().message;
            this.greenResult = result.green;
            this.yellowResult = result.yellow;
            this.redResult = result.red;
        });
    }

    private computeDates() {
        let startDate = moment();
        let endDate = moment();
        let rolling = false;
        let unitTime: moment.unitOfTime.DurationConstructor = 'days';
        let startOf: moment.unitOfTime.StartOf = 'days';
        let nbDays = 1;
        if (this.settings) {
            switch (this.settings.period) {
                case StatPeriod.LAST_ROLLING_YEAR:
                    rolling = true;
                    startOf = unitTime = 'year';
                break;

                case StatPeriod.LAST_YEAR:
                    startOf = unitTime = 'year';
                break;

                case StatPeriod.LAST_MONTH:
                    startOf = unitTime = 'month';
                break;

                case StatPeriod.LAST_WEEK:
                    startOf = 'isoWeek';
                    unitTime = 'week';
                break;

                case StatPeriod.DEFINED_DAYS_NUMBER:
                    nbDays = this.settings.nbDays;
                    rolling = true;
                break;
            }
        }

        const lastUnitPast = moment().subtract(nbDays, unitTime);
            startDate = lastUnitPast;
        if (!rolling) {
            startDate = moment(lastUnitPast).startOf(startOf);
            endDate = moment(lastUnitPast).endOf(startOf);
        }
        const dateFormat = 'YYYY-MM-DD';
        this.startDate = startDate.format(dateFormat);
        this.endDate = endDate.format(dateFormat);
    }

    saveLocalVotes() {
        localStorage.setItem(this.LOCAL_VOTE_KEY, JSON.stringify(this.localVotes));
    }
}
