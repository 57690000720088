import { Injectable } from '@angular/core';

@Injectable()
export class ImageHelperService {

    constructor() { }

    public decodeFromBase64(base64Image: string, defaultValue: string = '') {
        let result = defaultValue;
        if (base64Image) {
            result = "data:image/png;base64," + base64Image;
        }
        return result;
    }
}
