import { Injectable } from "@angular/core";

@Injectable()
export class FBNLanguageService {

  public readonly languages = {
    arabic: "ar",
    basque: "eu",
    danish: "da",
    dutch: "du",
    english: "en",
    estonian: "ee",
    finnish: "fi",
    french: "fr",
    german: "de",
    italian: "it",
    latvian: "lv",
    lithuanian: "lt",
    luganda: "lg",
    norwegian: "no",
    polish: "pl",
    portuguese: "pt",
    russian: "ru",
    serbian: "sr",
    slovak: "sk",
    spanish: "es",
    swedish: "sw",
  }

  public readonly codes = {
    ar: "Arabic",
    eu: "Basque",
    da: "Danish",
    du: "Dutch",
    en: "English",
    ee: "Estonian",
    fi: "Finnish",
    fr: "French",
    de: "German",
    it: "Italian",
    lv: "Latvian",
    lt: "Lithuanian",
    lg: "Luganda",
    no: "Norwegian",
    pl: "Polish",
    pt: "Portuguese",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    es: "Spanish",
    sw: "Swedish",
  }


  public getCode(language: string) {
    return this.languages[language.toLowerCase()];
  }
}

