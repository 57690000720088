import { Reason } from './../models/reason';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../providers/settings.service';

@Component({
  selector: 'modal-reasons',
  templateUrl: 'modal-reasons.html'
})
export class ModalReasonsPage implements OnInit {

  @Input() reasons: Array<Reason>;
  @Input() color: string;

  private timer: Subscription;
  private reason: Reason;

  constructor(
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    public settings: SettingsService
  ) {
    this.timer = null;
    this.reason = null;
    this.initTimer();
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      if (this.reasons && this.reasons.length) {
        this.reasons.forEach(r => r.translate(this.translate.currentLang));
      }
    });
  }
  initTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.timer = timer(this.settings.config.inactivityTimeOut * 1000).subscribe(() => {
      this.dismiss();
    });
  }

  reasonSelected(reason: Reason) {
    this.reason = reason;
    this.dismiss();
  }

  dismiss() {
    let data = {
      reason: this.reason
    };
    this.activeModal.dismiss(data);
  }
}
