import * as moment from 'moment';

export class VSBValidity{
    name: string;
    unlockDate: string;
    dateUpdate: string;

    constructor(name: string, unlockDate: string, dateUpdate: string) {
        this.name = name;
        this.unlockDate = unlockDate;
        this.dateUpdate = dateUpdate;
    }

    isLocked() {
        return moment(this.unlockDate).isAfter(moment());
    }
}