import { Config } from './../models/config-model';
import { FBNHttpProvider } from './fbn-http-provider';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { QuestionSFD } from '../models/question-sfd';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public onConfigInit: Subject<Config>;

  private sfdName: string;
  private isInit: boolean;

  private _config: Config;

  private get config(): Config {
    return this._config;
  }

  private set config(value: Config) {
    this._config = value;
    if (!this.isInit) {
      this.isInit = true;
      this.onConfigInit.next(value);
    }
  }

  constructor(
    private http: FBNHttpProvider
  ) {
    this.sfdName = '';
    this._config = null;
    this.isInit = false;
    this.onConfigInit = new Subject<Config>();
  }

  setSFDName(name: string) {
    this.sfdName = name;
  }

  getName() {
    return this.sfdName;
  }

  getQuestion(): Subject<QuestionSFD> {
    const subject = new Subject<QuestionSFD>();
    this.http.post('question', {SFDName: this.sfdName}).subscribe(response => {
      const json = this.exactractJSON(response);
      const question = QuestionSFD.createFromJSON(json);
      subject.next(question);
    });
    return subject;
  }

  getConfig(lazyLoad: boolean = true) {
    const subject = new Subject<Config>();
    if (!this.config && lazyLoad) {
      this.http.post('config', {SFDName: this.sfdName}).subscribe(response => {
        const json = this.exactractJSON(response);
        const config = Config.createFromJSON(json);
        subject.next(config);
        this.config = config;
      });
    } else {
      setTimeout(() => subject.next(this.config), 10);
    }
    return subject;
  }

  private exactractJSON(serverResponse: any) {
    let result = null;
    if (serverResponse) {
      result = serverResponse.json();
      if (result.message) {
        result = result.message;
      }
    }
    return result;
  }
}
