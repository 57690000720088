import {Injectable} from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { VSBValidity } from '../models/vsb-validity';

@Injectable({
  providedIn: 'root'
})
export class IdbService {

  private readonly storeName;
  constructor(
    private db: NgxIndexedDBService
  ) {
    this.storeName = 'vsb-validity'
  }


  findValidity(name): Promise<VSBValidity> {
    return this.db.getByID(this.storeName, name).then((row: any) => {
      let result = null;
      if (row) {
        result = new VSBValidity(row.name, row.unlockDate, row.dateUpdate);
      }
      return result;
    });
  }

  add(sfdName: string, unlockDate: string, dateUpdate: string) {
    return this.db.add(this.storeName, {name: sfdName, unlockDate: unlockDate, dateUpdate: dateUpdate});
  }

  delete(sfdName: string) {
    return this.db.delete(this.storeName, sfdName);
  }
}