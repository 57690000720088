import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';

@Component({
    selector: 'modal-thanks',
    templateUrl: 'modal-thanks.html',
    styleUrls: ['./modal-thanks.scss']
})
export class ModalThanks{

    public text: string;
    public logo: string;
    public displaySmiley: boolean;
    public colorSmiley: string;

    public constructor(
        private activeModal: NgbActiveModal
    ) { }
}