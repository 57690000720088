import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Http } from '@angular/http'


@Injectable()
export class FBNHttpProvider {

    private readonly DOMAIN                  = environment.api;
    private readonly SUB_ROUTE_PUBLIC_TABLET = 'public/virtual';

    constructor(
        private http: Http,
    ) { }

    public post(endPoint: string, data: any = null) {
        const finalUrl = this.generateUrl(endPoint);
        return this.http.post(finalUrl, data);
    }

    public get(endPoint: string, data: any = null) {
        const finalUrl = this.generateUrl(endPoint);
        return this.http.get(finalUrl, data);
    }

    private generateUrl(endPoint: string) {
        return [this.DOMAIN, this.SUB_ROUTE_PUBLIC_TABLET, endPoint].join('/');
    }
}