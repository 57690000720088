export class Lang{
    public name: string;
    public code: string;
    public codeCss: string; // to display correct class

    public constructor(name: string, code: string, codeCss: string = "") {
        this.name = name;
        this.code = code;
        if (codeCss.length === 0) {
            this.codeCss = code;
        } else {
            this.codeCss = codeCss
        }
    }
}