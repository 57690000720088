import { Injectable } from "@angular/core";
import { Config } from "../models/config-model";

@Injectable()
export class SettingsService {
  public config: Config;

  constructor() {
    this.config = null;
  }

  hasConfig(): boolean {
    return this.config !== null;
  }

}
