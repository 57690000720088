import { StatsSettings } from './stats-model';
import { SmileyColor } from './smiley-color';
import { SmileyImage } from './smiley-image';
import { Lang } from './lang';
export class Config {
  public ID: number;
  public sfdName: string;
  public mobileUrl: string;
  public contact: string;
  public design: number;
  public commentPolicy: string;
  public contactPolicy: string;
  public monthlyFees: number;
  public qrCode: string;
  public scenario: string;
  public reasonEnabled: boolean;
  public smileys: Array<SmileyImage>;
  public blockingTime: number;
  public textThankYou: string;
  public ipBlocked: boolean;
  public browserBlocking: number;
  public dateUpdate: string;
  public showStatEnabled: boolean;
  public statsSettings: StatsSettings;
  public level4: string;
  public level3: string;
  public level2: string;
  public level1: string;
  public languagesAvailable: Array<Lang>;
  public minCommentLength: number;
  public thankYouEnabled: boolean;
  public inactivityTimeOut: number;
  public commentInstructions: string;
  public commentPrompt: string;

  private _clientLogo: string;

  public get clientLogo(): string {
    return this._clientLogo;
  }

  public set clientLogo(value: string) {
    if (value) {
      this._clientLogo = (value ? "data:image/png;base64," + value : "");
    }
  }

  public get levelName() {
    return [
      this.level4,
      this.level3,
      this.level2,
      this.level1
    ].filter(level => level.length > 0).join(' - ');
  }
  public constructor(
    ID = 0,
    SFDName = "",
    level4 = "",
    level3 = "",
    level2 = "",
    level1 = "",
    mobileUrl = "",
    contact = "",
    design = 1,
    commentPolicy = '000',
    contactPolicy = '000',
    monthlyFees = 0,
    clientLogo = "",
    qrCode = "",
    scenario = "",
    reasonEnabled = false,
    button1Image = "",
    button2Image = "",
    button3Image = "",
    blockingTime = 0,
    textThankYou = "",
    blocked = false,
    browserBlocking = 0,
    dateUpdate = "",
    statsSettingsJSON = null,
    showStatEnabled = false,
    minCommentLength = 0,
    textThankYouEnabled = false,
    inactivityTimeout = 8,
    commentInstructions = '',
    commentPrompt = '',
  ) {
    this.ID = ID;
    this.sfdName = SFDName;
    this.mobileUrl = mobileUrl;
    this.contact = contact;
    this.design = design;
    this.contactPolicy = contactPolicy;
    this.commentPolicy = commentPolicy;
    this.monthlyFees = monthlyFees;
    this.clientLogo = clientLogo;
    this.qrCode = qrCode;
    this.scenario = scenario;
    this.reasonEnabled = reasonEnabled;
    this.blockingTime = blockingTime;
    this.textThankYou = textThankYou;
    this.minCommentLength = minCommentLength;
    this.inactivityTimeOut = inactivityTimeout;

    let smileyImage1 = new SmileyImage(false, button1Image, "", SmileyColor.GREEN);
    let smileyImage2 = new SmileyImage(false, button2Image, "", SmileyColor.YELLOW);
    let smileyImage3 = new SmileyImage(false, button3Image, "", SmileyColor.RED);

    this.smileys = new Array<SmileyImage>();

    this.smileys.push(smileyImage1);
    this.smileys.push(smileyImage2);
    this.smileys.push(smileyImage3);
    this.ipBlocked = blocked;
    this.browserBlocking = browserBlocking;
    this.dateUpdate = dateUpdate;
    this.showStatEnabled = showStatEnabled;
    this.thankYouEnabled = textThankYouEnabled;

    this.statsSettings = StatsSettings.createFromJSON(statsSettingsJSON);

    this.level4 = level4;
    this.level3 = level3;
    this.level2 = level2;
    this.level1 = level1;

    this.commentInstructions = commentInstructions;
    this.commentPrompt = commentPrompt;
  }

  public static createFromJSON(json: any) {
    let result = null;
    if (json && !('length' in json)) {
      if ('vsfd_Button1_image' in json) {
        json = this.convertJSONFromServer(json);
      }
      if (typeof json !== 'object') {
        json = JSON.parse(json);
      }

      const blockingTime = +json.blockingTime.split(" ")[0];
      const splittedBlockingTime = json.blockingTime.split(" ");
      let time = +splittedBlockingTime[0];
      const unitTime = splittedBlockingTime[1];

      if (unitTime == "minutes") {
        time *= 60;
      } else if (unitTime == "hours") {
        time *= 3600;
      }

      result = new Config(
        json.ID,
        json.SFDName,
        json.level4,
        json.level3,
        json.level2,
        json.level1,
        json.mobileUrl,
        json.contact,
        json.design,
        json.commentPolicy,
        json.contactPolicy,
        json.monthlyFees,
        json.clientLogo,
        json.qrCode,
        json.scenario,
        json.reasonEnabled,
        json.button1Image,
        json.button2Image,
        json.button3Image,
        time,
        json.textThankYou,
        json.isBlocked,
        json.browserBlocking,
        json.dateUpdate,
        json.statsSettings,
        json.statsEnabled !== 0,
        json.minCommentLength,
        json.textThankYouEnabled,
        json.inactivityTimeOut,
        json.commentInstructions,
        json.commentPrompt
      );
    }
    return result;
  }

  public static convertJSONFromServer(json: any) {
    return {
      ID: json.ID,
      SFDName: json.SFDName,
      mobileUrl: json.vsfd_MobileURL,
      contact: json.vsfd_Contact,
      design: json.vsfd_Design,
      commentPolicy: json.vsfd_CommentPolicy,
      contactPolicy: json.vsfd_ContactPolicy,
      monthlyFees: json.vsfd_MonthlyFees,
      clientLogo: json.vsfd_ClientLogo,
      qrCode: json.vsfd_QRCode,
      scenario: json.vsfd_QuestionScenario,
      reasonEnabled: json.vsfd_ReasonEnable,
      button1Image: json.vsfd_Button1_image,
      button2Image: json.vsfd_Button2_image,
      button3Image: json.vsfd_Button3_image,
      blockingTime: json.BlockingTime,
      textThankYou: json.vsfd_TextThankYou,
      isBlocked: json.isBlocked,
      browserBlocking: json.browserBlocking,
      dateUpdate: json.DateUpdate,
      statsSettings: StatsSettings.createJSONFromServer(json.statsSettings),
      statsEnabled: json.vsfd_TextThankYou_showstat_enable,
      level4: json.ClientGroupe,
      level3: json.ClientCompanyName,
      level2: json.ClientStoreName,
      level1: json.ClientStoreLocationName,
      minCommentLength: json.vsfd_CommentMinLength,
      textThankYouEnabled: json.vsfd_TextThankYou_enable !== 0,
      inactivityTimeOut: json.inactivityTimeout,
      commentInstructions: json.commentInstructions,
      commentPrompt: json.commentPrompt
    };
  }
}

export class QuestionScenario {
  public static readonly SINGLE = '1 sb';
  public static readonly RANDOM = 'Random';
  public static readonly SERIE = 'Serie';
  public static readonly TREE = 'Tree';
}
