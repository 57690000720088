export class SmileyColor{
    public static readonly GREEN  = 'green';
    public static readonly YELLOW = 'yellow';
    public static readonly RED    = 'red';

     public static readonly ALL = [
        SmileyColor.GREEN,
        SmileyColor.YELLOW,
        SmileyColor.RED
    ];

     public static getIndex(color: string) {
        return SmileyColor.ALL.indexOf(color.toLowerCase());
    }

     public static getColor(index: number) {
        return SmileyColor.ALL[index];
    }
} 
