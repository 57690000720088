export class SmileyImage{

    private _url: string;
    private _urlSelected: string;
    private is123: boolean;

    public color: string;

    public get url() {
        return this._url;
    }

    public set url(value: string) {
        let tmp = this.encodeImage(value);
        if(!tmp) {
            tmp = this.defaultUnselectedUrl();
        }
        this._url = tmp;
    }

    public get urlSelected() {
        return this._urlSelected;
    }

    public set urlSelected(value: string) {
        let tmp = this.encodeImage(value);
        if(!tmp) {
            tmp = this.defaultSelectedUrl();
        }
        this._urlSelected = tmp;
    }

    constructor(is123: boolean = false, url: string = "", urlSelected: string = "", color: string = "") {
        this.color       = color;
        this.is123       = is123;
        this.url         = url;
        this.urlSelected = urlSelected;
    }

    private encodeImage(url): string {
        return  url ? "data:image/png;base64," + url : "";
    }

    private defaultSelectedUrl(): string {
        return this.buildPath('WithFaceSelected');
    }

    private defaultUnselectedUrl(): string {
        return this.buildPath('WithFaceUnselected');
    }

    private buildPath(suffix: string) {
        const result = ["./assets/img/smileys/", this.color];
        if (this.is123) {
            result[0] += '123/';
            result[1] = 'button' + {green: 1, yellow: 2, red: 3}[this.color];
        } else {
            result[0] += 'feedback/';
            result.push(suffix);
        }
        result.push('.svg');
        return result.join('');
    }
}