import { Lang } from './lang';
import { Reason } from "./reason";
import { QuestionSerie } from "./question-serie";

export class QuestionSFD {
  public id: number;
  public question1Language: string;
  public question2Language: string;
  public question3Language: string;
  public question4Language: string;
  public question5Language: string;

  public question1Text: string;
  public question2Text: string;
  public question3Text: string;
  public question4Text: string;
  public question5Text: string;

  public fromSerie: boolean;
  public showComment: boolean;

  public displayableForCurrentLanguage: boolean;

  public reasons: Array<Reason>;
  public series: Array<QuestionSerie>;
  public validity: number; //seconds

  public static languagesCode: any;

  public static CURRENT_LANGUAGE = '';

  public readonly maxLanguages = 5;

  public commentTitle: string;
  public commentSubtitle: string;

  public constructor(
    id: number = -1,
    question1Language: string = "",
    question2Language: string = "",
    question3Language: string = "",
    question4Language: string = "",
    question5Language: string = "",
    question1Text: string = "",
    question2Text: string = "",
    question3Text: string = "",
    question4Text: string = "",
    question5Text: string = "",
    reasons: Array<Reason> = [],
    series: Array<QuestionSerie> = [],
    validity: number = 300,
    fromSerie: boolean = false,
    showComment: boolean = false,
    commentTitle: string = '',
    commentSubtitle: string = ''
  ) {
    this.id = id;
    this.question1Language = question1Language;
    this.question2Language = question2Language;
    this.question3Language = question3Language;
    this.question4Language = question4Language;
    this.question5Language = question5Language;
    this.question1Text = question1Text;
    this.question2Text = question2Text;
    this.question3Text = question3Text;
    this.question4Text = question4Text;
    this.question5Text = question5Text;
    this.reasons = reasons;
    this.series = series;
    this.displayableForCurrentLanguage = false;
    this.validity = validity;
    this.fromSerie = fromSerie;
    this.showComment = showComment;
    this.commentTitle = commentTitle;
    this.commentSubtitle = commentSubtitle;
  }

  public static createFromJSON(json: any) {
    let result: QuestionSFD = null;


    if (json.EventQuestion) {
      result = new QuestionSFD();
      result.question1Language = 'english';
      result.question1Text = json.EventQuestion;
      result.validity = json.remainSeconds;
    } else {
      result = new QuestionSFD(
        +json.id,
        json.Question1Language,
        json.Question2Language,
        json.Question3Language,
        json.Question4Language,
        json.Question5Language,
        json.Question1Text,
        json.Question2Text,
        json.Question3Text,
        json.Question4Text,
        json.Question5Text,
        Reason.createFromJsonList(json.reasons),
        QuestionSerie.createFromJsonList(json.series)
      );
    }

    return result;
  }

  public static copy(question: QuestionSFD): QuestionSFD {
    let result: QuestionSFD = null;
    if (question) {
      let reasons = Reason.copyList(question.reasons);
      let series = QuestionSerie.copyList(question.series);
      result = new QuestionSFD(
        question.id,
        question.question1Language,
        question.question2Language,
        question.question3Language,
        question.question4Language,
        question.question5Language,
        question.question1Text,
        question.question2Text,
        question.question3Text,
        question.question4Text,
        question.question5Text,
        reasons,
        series,
      );
      result.commentTitle = question.commentSubtitle;
      result.commentSubtitle = question.commentSubtitle;
    }
    return result;
  }

  public getDataForLang(languageCode: string) {
    const result = {
      id: this.id,
      questionLanguage: '',
      questionText: ''
    };

    let language = QuestionSFD.languagesCode[languageCode];
    if (language) {
      for (let i = 1; i < this.maxLanguages + 1; i++) {
        const base = 'question' + i;
        const languageKey = base + 'Language';
        if (this[languageKey] === language) {
          result.questionLanguage = this[languageKey];
          result.questionText = this[base + 'Text'];
          break;
        }
      }
    }

    return result;
  }

  public computeQuestionDisplayableForLanguage(withCurrentLang: boolean = true) {
    for (let i = 1; i < this.maxLanguages + 1; i++) {
      const base = 'question' + i;
      const languageKey = base + 'Language';
      const displayableField = base + 'Displayable';
      const text = base + 'Text';
      if (this[text] && (this[languageKey] === QuestionSFD.languagesCode[QuestionSFD.CURRENT_LANGUAGE] || !withCurrentLang)) {
        this[displayableField] = true;
        break;
      } else {
        this[displayableField] = false;
      }
    }
  }

  public getCurrentText() {
    let result = '';
    this.computeQuestionDisplayableForLanguage();
    for (let i = 1; i < this.maxLanguages + 1; i++) {
      if (this['question' + i + 'Displayable']) {
        result = this['question' + i + 'Text'];
        break;
      }
    }
    return result;
  }

  public getCurrentLanguage() {
    let result = '';
    this.computeQuestionDisplayableForLanguage(false);
    for (let i = 1; i < this.maxLanguages + 1; i++) {
      if (this['question' + i + 'Displayable']) {
        result = this['question' + i + 'Language'];
        break;
      }
    }
    return result;
  }

  public getLanguages() {
    let result = new Array<Lang>();
    const code = Object.keys(QuestionSFD.languagesCode).reduce((obj, key) => {
      obj[QuestionSFD.languagesCode[key]] = key;
      return obj;
    }, {});
    for (let i = 1; i < 6; i++) {
      if (this['question' + i + 'Text'] && this['question' + i + 'Language']) {
        const langFull = this['question' + i + 'Language'];
        const langCode = code[this['question' + i + 'Language']];
        result.push(new Lang(langFull, langCode));
      }
    }
    return result;
  }

  public static createFromQuestionSerie(serie: QuestionSerie) {
    const result = new QuestionSFD(
      serie.id,
      serie.language1,
      serie.language2,
      serie.language3,
      serie.language4,
      serie.language5,
      serie.text1,
      serie.text2,
      serie.text3,
      serie.text4,
      serie.text5,
      Reason.copyList(serie.reasons),
      [],
      300,
      true,
      serie.showComment,
      serie.commentTitle,
      serie.commentSubtitle
    );
    return result;
  }
}
