import { Choice } from './choice';
import { IndexFactory } from './index-factory';
import * as moment from 'moment';
import { Reason } from './reason';

export class Vote{
    private _internalId: number;
    public static readonly DATE_FORMAT = 'YYYY-MM-DD HH-mm-ss';


    public get internalId() {
        return this._internalId;
    }

    public set comment(value: string) {
        this._comment = value.replace(/(;)/g, '-'); // replace with a dash
        this._comment = value.replace(/(\n|\r)/g, '');
        if (this._comment.length > 255) {
            this._comment = this._comment.substr(0, 255);
        }
    }

    public get comment():string {
        return this._comment;
    }

    public color: string;
    public SFDName: string;
    public date: string;
    public sent: boolean;
    public contactDetail: string;
    public question: any;
    public questionText: string;

    private reasons: Array<Reason>;
    private _comment: string;

    constructor(color: string = '', comment: string = '', sfdName: string = '', contactDetail: string = '', reasons: Array<Reason> = []) {
        this.color         = color;
        this.comment       = comment;
        this.SFDName       = sfdName;
        this.date          = moment().format(Vote.DATE_FORMAT);
        this.sent          = false;
        this._internalId   = IndexFactory.getId();
        this.contactDetail = contactDetail;
        this.reasons       = reasons;
        this.questionText  = "";
    }

    public addReason(reason: Reason) {
        if (reason) {
            let cpy = Reason.copy(reason);
            if (cpy) {
                cpy.icon = "";
            }
            this.reasons.push(cpy);
        }
    }

    public static createFromChoices(list: Array<Choice>, sfdName: string): Array<Vote> {
        let numberSeconds = 0;
        return list.map(choice => {
            const vote = choice.toVote(sfdName);
            vote.date  = moment().add(numberSeconds, 'seconds').format('YYYY-MM-DD HH-mm-ss');
            numberSeconds++;
            return vote;
        });
    }

    public static createFromJSON(json: any) {
        const result = new Vote(json.color, json.comment, json.SFDName, json.contactDetail, Reason.copyList(json.reasons));
        result.date = json.date;
        return result;
    }

    public static createListFromJSON(list: Array<any>): Array<Vote> {
        return list.map(json => this.createFromJSON(json));
    }

    public setReasonsText(currentLang: string) {
        this.reasons.forEach(reason => {
            reason.translate(currentLang);
        })
    }
}
