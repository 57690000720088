import { Component, OnInit, Input } from '@angular/core';
import { FBNHttpProvider } from '../providers/fbn-http-provider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-campaign',
  templateUrl: './modal-campaign.html',
  styleUrls: ['./modal-campaign.scss']
})
export class ModalCampaign implements OnInit {

  @Input() campaign_id: string;
  @Input() recipient_id: string;

  isLoading: boolean;
  granted: boolean;
  message: string;
  isWarning: boolean;

  constructor(
    private http: FBNHttpProvider,
    public activeModal: NgbActiveModal
  ) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.campaign_id && this.recipient_id) {
      this.http.post('check-campaign', {
        campaign_id: this.campaign_id,
        recipient_id: this.recipient_id
      }).subscribe(response => {
        const result = response.json().message;
        this.granted = result.granted;

        if (result.granted) {
          this.activeModal.dismiss();
        } else {
          if (result.alreadyVoted) {
            this.message = 'You have already voted!';
            this.isWarning = true;
          } else {
            this.message = "Error with your campaign informations";
            this.isWarning = false;
          }
        }
        this.isLoading = false;
      });
    }
  }

}
