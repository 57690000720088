import { DBConfig } from 'ngx-indexed-db/ngx-indexed-db';

export const dbConfig: DBConfig = {
    name: 'VSB-local-datas', version: 1, objectStoresMeta: [{
        store: 'vsb-validity',
        storeConfig: { keyPath: 'name', autoIncrement: true },
        storeSchema: [
            { name: 'name', keypath: 'name', options: { unique: false }},
            { name: 'unlockDate', keypath: 'unlockDate', options: { unique: false }},
            { name: 'dateUpdate', keypath: 'dateUpdate', options: { unique: false }},
        ]
    }]
};