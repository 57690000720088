import { Vote } from './votes';
import { QuestionSFD } from './question-sfd';
import { Reason } from './reason';
export class Choice {
    public color: string;
    public questionText: string;
    
    public set reason(value: Reason) {
        if (value) {
            this._reason = Reason.copy(value);
        }
    }

    public get reason(): Reason {
        return this._reason;
    }
    private _reason: Reason;
    public comment: string;

    public set question (value: QuestionSFD) {
        if (value) {
            this.questionText = value.getCurrentText();
        }
    }

    public constructor(color = "", question: QuestionSFD = null) {
        this.color        = color;
        this.question     = question;
        this.reason       = null;
        this.comment      = "";
    }

    public toVote(sfdName: string) {
        const vote = new Vote(this.color, this.comment, sfdName);
        vote.questionText = this.questionText;
        vote.addReason(this.reason);
        return vote;
    }

    public static copy(choice: Choice) {
        const result        = new Choice(choice.color);
        result.questionText = choice.questionText;
        result.reason       = choice.reason;
        result.comment      = choice.comment;
        return result;
    }

    public hasReason() {
        return this.reason !== null && this.reason !== undefined;
    }
}