import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { SettingsService } from '../providers/settings.service';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.scss']
})
export class ModalContactComponent implements OnInit {


  public contact: string;
  public showSubmitButton: boolean;
  public questionText: string;

  private timer: Subscription;

  constructor(
      public activeModal: NgbActiveModal,
      public settings: SettingsService
  ) {
      this.contact          = '';
      this.showSubmitButton = false;
      this.timer            = null;

      this.initTimer();
  }

  initTimer() {
      if (this.timer) {
          this.timer.unsubscribe();
      }
      this.timer = timer(this.settings.config.inactivityTimeOut * 1000).subscribe(() => {
          this.dismiss();
      });
  }

  ngOnInit() { }

  dismiss() {
      let data = {
          contact: this.contact
      };
      this.activeModal.dismiss(data);
  }

  keyPressed() {
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if (regexp.test(this.contact)) {
          this.showSubmitButton = true;
      } else {
          this.showSubmitButton = false;
      }
      this.initTimer();
  }

  buttonClicked() {
      this.dismiss();
  }

}
