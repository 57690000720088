export class StatsSettings{
    public text: string;
    public displayedInfo: number;
    public period: number;
    public nbDays: number;
    public graphType: number;

    public constructor(
        text: string          = '',
        displayedInfo: number = 1,
        period: number        = 5,
        nbDays: number        = 0,
        graphType: number     = 3,
    ) {
        this.text = text;
        this.displayedInfo = displayedInfo;
        this.period = period;
        this.nbDays = nbDays;
        this.graphType = graphType;
    }

    public static createFromJSON(json: any): StatsSettings {
        let result = null;
        if (json) {
            result = new StatsSettings(
                json.text,
                json.displayedInfo,
                json.period,
                json.nbDays,
                json.graphType
            );
        }
        return result;
    }

    public static createJSONFromServer(json: any) {
        let result = null;
        if (json) {
            result = {
                text: json.ThankYou_Stat_Text,
                displayedInfo: json.ThankYou_Stat_What,
                period: json.ThankYou_Stat_When,
                nbDays: json.ThankYou_Stat_WhenLast,
                graphType: json.ThankYou_Stat_GraphType
            };
        }
        return result;
    }

}

export class StatDisplayedInfo {
    public static readonly NONE = 0;
    public static readonly NUMBER_VOTES = 1;
    public static readonly PERCENTAGE = 2;
    public static readonly NUMBER_PERCENTAGE_VOTES = StatDisplayedInfo.NUMBER_VOTES + StatDisplayedInfo.PERCENTAGE;
}

export class StatPeriod {
    public static readonly LAST_ROLLING_YEAR   = 1;
    public static readonly LAST_YEAR           = 2;
    public static readonly LAST_MONTH          = 3;
    public static readonly LAST_WEEK           = 4;
    public static readonly YESTERDAY           = 5;
    public static readonly DEFINED_DAYS_NUMBER = 6;
}

export class StatGraphType {
    public static readonly PIE_2D = 1;
    public static readonly PIE_3D = 2;
    public static readonly DONUT  = 3;
}