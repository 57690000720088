import { QuestionSFD } from './../models/question-sfd';
import { FBNLanguageService } from './../providers/fbn-language.service';
import { Config } from './../models/config-model';
import { ActivatedRoute } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Engine } from '../providers/votes-services/engine';
import { CampaignDisplayer } from '../providers/campaign-displayer';
import { LoaderService } from '../providers/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../providers/settings.service';

@Component({
  selector: 'app-vote-page',
  templateUrl: './vote-page.component.html',
  styleUrls: ['./vote-page.component.scss']
})
export class VotePageComponent implements OnInit {
  vsfdName: string;
  loading: boolean;
  cssFile: SafeResourceUrl;
  questionText: string;
  levels: string;
  config: Config;
  additionnalCssFound: boolean;
  today: number = Date.now();
  timeRefresh: number; //seconds
  votesEnabled: boolean;
  campaignChecking: boolean;
  choice: string;
  campaign_id: number;
  recipient_id: number;
  fromCampaign: boolean;
  cssFileExists: boolean;
  currentLanguage: string;
  readonly defaultLang: string;
  private _question: QuestionSFD;

  set question(value: QuestionSFD) {
    if (value) {
      this._question = value;
      this.choice = '';
      this.votesEnabled = true;
      this.setQuestionText();
    }
  }

  get question(): QuestionSFD {
    return this._question;
  }

  constructor(
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private loader: LoaderService,
    public engine: Engine,
    public fbnLanguage: FBNLanguageService,
    public campaignDisplayer: CampaignDisplayer,
    private translate: TranslateService,
    private settings: SettingsService
  ) {
    this.mapParams();
    this.cssFile = null;
    this.additionnalCssFound = true;
    this.questionText = '';
    this.levels = '';
    this.votesEnabled = true;
    this.campaignChecking = false;
    this.cssFileExists = false;
    this.defaultLang = 'en';
    this.setDefaultLanguage();
    this.setLanguage();
    this.translate.onLangChange.subscribe(event => {
      this.setLanguage();
    });
    this.engine.getEventSubscriber().subscribe(event => {
      switch(event.name) {
        case 'question':
          const question = event.data.question;
          const justInit = event.data.justInit;
          if (justInit) {
            this.question = question;
          } else {
            setTimeout(() => {
              this.question = question;
            }, 1000);
          }
          break;
        case 'reset':
          if (event.data.propagate) {
            this.setDefaultLanguage();
            this.load();
          }
          this.checkCampaign();
          break;
      }
    });
  }

  private setDefaultLanguage() {
    this.translate.use(this.defaultLang);
  }
  private setLanguage() {
    this.setCurrentLanguage();
    this.setQuestionText();
  }

  private setCurrentLanguage() {
    this.currentLanguage = this.translate.currentLang;
    QuestionSFD.CURRENT_LANGUAGE = this.currentLanguage;
  }

  private setQuestionText() {
    if (this.question) {
      this.questionText = this.question.getCurrentText();
    } else {
      this.questionText = "";
    }
  }
  private mapParams() {
    const fields = {vsfdName: 'string', campaign_id: 'number', recipient_id: 'number'};
    Object.keys(fields).forEach((key) => {
      let tmp: any = this.route.snapshot.paramMap.get(key);
      if (fields[key] === 'number') {
        tmp = +tmp;
      }
      this[key] = tmp;
    });

    this.loader.setSFDName(this.vsfdName);
  }

  ngOnInit() {
    this.checkCampaign();
    if (this.vsfdName) {
      const assets = '/assets/styles/' + this.vsfdName + '.css';
      this.cssFile = this.sanitizer.bypassSecurityTrustResourceUrl(assets);
      this.fileExists(assets, result => {
        if (result) {
          this.cssFile = this.sanitizer.bypassSecurityTrustResourceUrl(assets);
        }
      });

      this.load();
    }
  }

  choose(color: string) {
    if (this.votesEnabled && !this.campaignChecking) {
      this.choice = color;
      this.votesEnabled = false;
      this.engine.vote(color, this.recipient_id);
    }
  }

  checkCampaign() {
    this.campaignChecking = true;
    if (this.campaign_id && this.recipient_id) {
      this.campaignDisplayer.display(this.campaign_id, this.recipient_id).subscribe(() => {
        this.campaignChecking = false;
      });
    } else {
      this.campaignChecking = false;
    }
  }

  load() {
    this.votesEnabled = false;
    this.levels = '';
    this.choice = '';
    this.loader.getQuestion().subscribe((question: QuestionSFD) => {
      this.loader.getConfig().subscribe(config => {
        this.config = config;
        this.settings.config = config;
        this.engine.init(question, this.config);
        this.levels = config.levelName;
        this.timeRefresh = question.validity;
        this.launchReloader();
        this.votesEnabled = true;
        this.config.languagesAvailable = question.getLanguages();
      });
    });
  }

  fileExists(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true); };
    img.onerror = function() { callback(false); };
    img.src = url;
  }

  launchReloader() {
    setTimeout(() => {
      this.load();
    }, this.timeRefresh * 1000);
  }
}
