import { VotesStatsService } from './votes-stats.service';
import { LoaderService } from './../providers/loader.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'highcharts';
import { Subscription } from 'rxjs';

import * as Highcharts from 'highcharts';
import { StatGraphType, StatPeriod, StatDisplayedInfo, StatsSettings } from '../models/stats-model';
@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit, OnDestroy {

  public chart: Chart;
  public logoClient: string;
  public data: Array<any>;
  public text: string;
  public logoFbn: string;
  public isViewLoaded: boolean;

  private subscription: Subscription;

  private _settings: StatsSettings;

  get settings() {
    return this._settings;
  }

  set settings(value: StatsSettings) {
    if (!value) {
      this._settings = new StatsSettings();
    } else {
      this._settings = value;
    }
    const data = this.votesStats.getDatas();
    this.data = this.computeData(data);
    this.createChart();
  }

  public readonly colors = ['#65b32e', '#ffd600', '#e94b5b'];

  private greenResult: number;
  private percentLabels: any

  @ViewChild('rowChart', {static: false}) rowChart: ElementRef;
  @ViewChild('rowText', {static: false}) rowText: ElementRef;
  @ViewChild('mainCol', {static: false}) mainCol: ElementRef;

  constructor(
    private loader: LoaderService,
    private votesStats: VotesStatsService
  ) {
    this.text = '';
    this.greenResult = 0;
    this.logoClient = '';
    this.subscription = null;
    this.isViewLoaded = false;

    this.initFormats();
  }

  ngOnInit() {
    this.isViewLoaded = true;
    this.subscription = this.loader.getConfig().subscribe(config => {
      if (config) {
        this.settings   = config.statsSettings;
        this.text       = this.settings.text;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initFormats() {
    this.percentLabels = {
      green: '',
      yellow: '',
      red: ''
    };
  }

  computeData(data: Array<any>) {
    this.initFormats();
    let totalVote = data.reduce((sum, d) => sum += d[1], 0);
    if (!totalVote) {
      totalVote = 1;
    }
    return data.map(d => [...d, ((d[1] / totalVote) * 100).toFixed(0)]).map((d, i) => {
      const name = d[0];
      const number = d[1];
      const percent = d[2];
      const plot: any = {
        name: name,
        color: this.colors[i]
      };
      plot['y'] = number;
      if (this.settings.displayedInfo === StatDisplayedInfo.NUMBER_VOTES || this.settings.displayedInfo === StatDisplayedInfo.NUMBER_PERCENTAGE_VOTES) {
        this.percentLabels[name] = '#' + number;
      }
      if (this.settings.displayedInfo === StatDisplayedInfo.PERCENTAGE || this.settings.displayedInfo === StatDisplayedInfo.NUMBER_PERCENTAGE_VOTES) {
        plot['percent'] = percent;
        if (this.percentLabels[name].length) {
          this.percentLabels[name] += '<br>';
        }
        this.percentLabels[name] += percent + '%';
      }

      if (name == 'green') {
        this.greenResult = percent;
      }
      return plot;
    });
  }

  createChart() {
    const textColor = '#000000';
    const self = this;
    const chartTitle = '<b style="color: #65b32e">' + this.greenResult + '%</b>' + '<b style="color: ' + textColor + '"> satisfaction</b>';
    let prefix = '';
    switch(this.settings.period) {
      case StatPeriod.DEFINED_DAYS_NUMBER:
        prefix = 'Last ' + this.settings.nbDays + ' days';
        break;
      case StatPeriod.LAST_ROLLING_YEAR:
        prefix = 'Last rolling year';
        break;
      case StatPeriod.LAST_YEAR:
        prefix = 'Last year';
        break;
      case StatPeriod.LAST_MONTH:
        prefix = 'Last month';
        break;
      case StatPeriod.LAST_WEEK:
        prefix = 'Last week';
        break;
    }

    if (prefix) {
      prefix = '<br><b style="color: ' + textColor + '">' + prefix + '</b>'
    }


    let innerSize = '0';
    let verticalAlignment: "bottom" | "middle" | "top" = 'bottom';
    let enable3d = false;
    if (this.settings.graphType === StatGraphType.DONUT) {
      innerSize = '55%';
      verticalAlignment = 'middle';
    } else if (this.settings.graphType === StatGraphType.PIE_3D) {
      enable3d = true;
    }

    let rowChartHeight: any ='75%';

    this.chart = Highcharts.chart('chart-container', {
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: null,
        type: 'pie',
        height: rowChartHeight,
        options3d: {
          enabled: enable3d,
          alpha: 45,
          beta: 0
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          dataLabels: {
            enabled: true,
            distance: -65,
            style: {
              color: 'white',
              fontSize: '2rem',
              textOutline: 'none'
            }
          }
        }
      },
      tooltip: { enabled: false },
      title: {
        text: chartTitle + prefix,
        style: {
          fontSize: '3rem',
          fontWeight: 'bold'
        },
        align: 'center',
        verticalAlign: verticalAlignment
      },
      series: [{
          type: 'pie',
          data: this.data,
          size: '100%',
          innerSize: innerSize,
          dataLabels: {
            formatter: function () {
              const __this: any = this;
              return self.percentLabels[__this.key];
            }
          }
      }]
    });
  }
}
