import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FBNHttpProvider } from '../fbn-http-provider';
import { Vote } from '../../models/votes';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class VotesManager {

    public constructor(
        private http: FBNHttpProvider,
        private translateService: TranslateService
    ) {}

    public sendVotes(votes: Array<Vote>, contact: string = "", recipient_id: number = null): Subject<any>{
        const subject = new Subject<any>();
        votes.forEach(vote => {
            vote.setReasonsText(this.translateService.currentLang);
        });

        const param = {
            votes: votes
        }

        if (contact) {
            param['contactDetail'] = contact;
        }

        if (recipient_id) {
            param['recipient_id'] = recipient_id;
            param['dateVote'] = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        this.http.post('vote', param).subscribe(success => {
            const content = success.json();
            const isBlocked = content ? (content.extra ? !!content.extra.isBlocked : false) : false;
            subject.next({isBlocked: isBlocked});
        });

        return subject;
    }
}
