import { ThankYouPageComponent } from './../thank-you-page/thank-you-page.component';
import { ModalThanks } from './../modal-thanks/modal-thanks';
import { Subject, timer } from 'rxjs';
import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptionsService } from './helpers/modal-options.service';

@Injectable()
export class ThanksDisplayer{

    private modal: NgbModalRef;

    public constructor(
        private modalService: NgbModal,
        private modalOptions: ModalOptionsService
    ) { }

    public display(text: string = '', time: number = -1, showStat: boolean, dismiss: boolean = false): Subject<any> {
        let result = new Subject<any>();
        if (showStat) {
            this.createStatsModal(time);
        } else {
            this.createTextModal(text);
        }

        if (time < 0) {
            time = 10;
        }
        if (dismiss) {
            timer(time * 1000).subscribe(() => {
                this.dismissModal();
                result.next();
            });
        }
        return result;
    }

    public createStatsModal(time: number) {
        this.dismissModal();
        this.modal = this.modalService.open(ThankYouPageComponent, this.modalOptions.getOptions('xl'));
    }

    public createTextModal(text: string = '') {
        this.dismissModal();
        this.modal = this.modalService.open(ModalThanks, this.modalOptions.getOptions());
        this.modal.componentInstance.text = text;
    }

    private dismissModal() {
        if (this.modal) {
            this.modal.dismiss();
            this.modal = null;
        }
    }
}
