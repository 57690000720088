import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Lang } from '../models/lang';
import { Config } from '../models/config-model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public langs: Array<Lang>;
  public currentLang: string;

  @Input()
  set config(config: Config) {
    if (config && config.languagesAvailable && config.languagesAvailable.length) {
      this.langs = config.languagesAvailable.slice();
    }
  }

  public constructor(
    public translateService: TranslateService,
  ) {
    this.langs = [];
  }

  ngOnInit() {
    this.setLang();
    this.translateService.onLangChange.subscribe(event => {
      this.setLang();
    });

  }

  select(code: string) {
    this.translateService.use(code);
  }

  private setLang() {
    this.currentLang = this.translateService.currentLang;
  }

  public getBackground(code) {
    return 'url("../../assets/img/flat-flags/' + code + '.png")';
  }
}
